import { ErrorInfo } from 'react';

import { trackError } from './trackError';
import { RUMCustomErrorContext } from './types';

function wrapComponentError(error: Error, info: ErrorInfo) {
  const renderingError = new Error(error.message);
  renderingError.name = 'ReactRenderingError';
  renderingError.stack = info.componentStack;
  renderingError.cause = error;

  return renderingError;
}

export function trackComponentError(
  error: Error,
  info: ErrorInfo,
  { isCrash, severity, url }: RUMCustomErrorContext = {},
) {
  trackError(wrapComponentError(error, info), { isCrash, severity, url });
}
